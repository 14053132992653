import React from "react";
import {
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MenuCloseIcon } from "../icons";
import { RichText } from "../textFields";
import { DialogCloseButton, DialogHeading, StyledDialog } from "./styles";

type PpDialog = {
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  popupContent: React.ReactElement;
  title?: string;
};

export function Dialog({
  description,
  isOpen,
  onClose,
  popupContent,
  title,
}: PpDialog): React.ReactElement {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledDialog
      aria-labelledby="transitional-cta-popup-dialog"
      fullScreen={isMobile}
      fullWidth={!isMobile}
      maxWidth={isMobile ? false : "md"}
      open={isOpen}
      onClose={onClose}
    >
      <DialogCloseButton disableRipple onClick={onClose}>
        <MenuCloseIcon fontSize="large" />
      </DialogCloseButton>
      <DialogHeading container alignItems="center" direction="column">
        {title && (
          <Typography gutterBottom align="center" color="primary" variant="h2">
            {title}
          </Typography>
        )}
        {description && (
          <RichText
            align="center"
            color="primary"
            text={description}
            variant="body1"
          />
        )}
      </DialogHeading>
      <DialogContent>{popupContent}</DialogContent>
    </StyledDialog>
  );
}
