import React, { useState } from "react";
import { Dialog } from "../../dialogs";
import { StyledButton } from "./styles";

type PpTransitionalCTAPopup = {
  buttonText: string;
  description: string;
  popupContent: React.ReactElement;
  title: string;
};

export function TransitionalCTAPopup({
  buttonText,
  description,
  popupContent,
  title,
}: PpTransitionalCTAPopup): React.ReactElement {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <StyledButton
        color="secondary"
        variant="contained"
        onClick={(): void => setOpen(true)}
      >
        {buttonText}
      </StyledButton>
      <Dialog
        description={description}
        isOpen={open}
        popupContent={popupContent}
        title={title}
        onClose={(): void => setOpen(false)}
      />
    </React.Fragment>
  );
}
