import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Dialog, Grid, IconButton } from "@mui/material";

export const DialogHeading = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    margin: ${theme.spacing(4, "auto", 2)};
    width: 80%;
    ${theme.breakpoints.down("md")} {
      width: 70%;
    }
  `}
`;

export const DialogCloseButton = styled(IconButton)`
  ${({ theme }): SerializedStyles => css`
    position: absolute;
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
    padding: ${theme.spacing(1.5)};
    cursor: pointer;
    &:hover {
      background: none;
    }
    ${theme.breakpoints.down("md")} {
      padding: 0;
    }
  `}
`;

export const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
  }
`;
